import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _8e80517e = () => interopDefault(import('../pages/404/index.vue' /* webpackChunkName: "pages/404/index" */))
const _24784c02 = () => interopDefault(import('../pages/additional-courses/index.vue' /* webpackChunkName: "pages/additional-courses/index" */))
const _1c4468ea = () => interopDefault(import('../pages/articles/index.vue' /* webpackChunkName: "pages/articles/index" */))
const _e9bed37e = () => interopDefault(import('../pages/documents/index.vue' /* webpackChunkName: "pages/documents/index" */))
const _f2765768 = () => interopDefault(import('../pages/ege/index.vue' /* webpackChunkName: "pages/ege/index" */))
const _a5675938 = () => interopDefault(import('../pages/ege-ceny/index.vue' /* webpackChunkName: "pages/ege-ceny/index" */))
const _74097f1e = () => interopDefault(import('../pages/enrollment/index.vue' /* webpackChunkName: "pages/enrollment/index" */))
const _3a3c7700 = () => interopDefault(import('../pages/externat/index.vue' /* webpackChunkName: "pages/externat/index" */))
const _30724130 = () => interopDefault(import('../pages/externat-ceny/index.vue' /* webpackChunkName: "pages/externat-ceny/index" */))
const _cd5f2b8e = () => interopDefault(import('../pages/externat-dlya-vzroslyh/index.vue' /* webpackChunkName: "pages/externat-dlya-vzroslyh/index" */))
const _5b043759 = () => interopDefault(import('../pages/free/index.vue' /* webpackChunkName: "pages/free/index" */))
const _375c6a5b = () => interopDefault(import('../pages/gazprom-bonus/index.vue' /* webpackChunkName: "pages/gazprom-bonus/index" */))
const _7f094cd4 = () => interopDefault(import('../pages/halloween/index.vue' /* webpackChunkName: "pages/halloween/index" */))
const _7524ecff = () => interopDefault(import('../pages/happy-new-year-2024/index.vue' /* webpackChunkName: "pages/happy-new-year-2024/index" */))
const _13721baa = () => interopDefault(import('../pages/intensiv-ege/index.vue' /* webpackChunkName: "pages/intensiv-ege/index" */))
const _47653335 = () => interopDefault(import('../pages/intensiv-oge/index.vue' /* webpackChunkName: "pages/intensiv-oge/index" */))
const _035b51a6 = () => interopDefault(import('../pages/intensive/index.vue' /* webpackChunkName: "pages/intensive/index" */))
const _4a0e0d80 = () => interopDefault(import('../pages/itogovoe-sobesedovanie-2024/index.vue' /* webpackChunkName: "pages/itogovoe-sobesedovanie-2024/index" */))
const _52178261 = () => interopDefault(import('../pages/itogovoe-sochinenie/index.vue' /* webpackChunkName: "pages/itogovoe-sochinenie/index" */))
const _6e75016b = () => interopDefault(import('../pages/kod-budushchego/index.vue' /* webpackChunkName: "pages/kod-budushchego/index" */))
const _35f003ac = () => interopDefault(import('../pages/marathon-ege-2024/index.vue' /* webpackChunkName: "pages/marathon-ege-2024/index" */))
const _7e4aec31 = () => interopDefault(import('../pages/marathon-ege-2024-drb/index.vue' /* webpackChunkName: "pages/marathon-ege-2024-drb/index" */))
const _6f9afe35 = () => interopDefault(import('../pages/marathon-ege-matematika-2024/index.vue' /* webpackChunkName: "pages/marathon-ege-matematika-2024/index" */))
const _0558216e = () => interopDefault(import('../pages/marathon-ege-russkij-2024/index.vue' /* webpackChunkName: "pages/marathon-ege-russkij-2024/index" */))
const _36d942b5 = () => interopDefault(import('../pages/materials/index.vue' /* webpackChunkName: "pages/materials/index" */))
const _d5d21f44 = () => interopDefault(import('../pages/oferta/index.vue' /* webpackChunkName: "pages/oferta/index" */))
const _5039d554 = () => interopDefault(import('../pages/oge/index.vue' /* webpackChunkName: "pages/oge/index" */))
const _5aff289a = () => interopDefault(import('../pages/oge-ceny/index.vue' /* webpackChunkName: "pages/oge-ceny/index" */))
const _5c494592 = () => interopDefault(import('../pages/online-school/index.vue' /* webpackChunkName: "pages/online-school/index" */))
const _65fb419c = () => interopDefault(import('../pages/online-school-2/index.vue' /* webpackChunkName: "pages/online-school-2/index" */))
const _f7e4b54e = () => interopDefault(import('../pages/online-school-ceny/index.vue' /* webpackChunkName: "pages/online-school-ceny/index" */))
const _54bc093e = () => interopDefault(import('../pages/online-school-ceny-2/index.vue' /* webpackChunkName: "pages/online-school-ceny-2/index" */))
const _55c59da5 = () => interopDefault(import('../pages/online-school-ceny-dubai/index.vue' /* webpackChunkName: "pages/online-school-ceny-dubai/index" */))
const _2e301850 = () => interopDefault(import('../pages/online-school-ceny-poddomen/index.vue' /* webpackChunkName: "pages/online-school-ceny-poddomen/index" */))
const _6549b46b = () => interopDefault(import('../pages/online-school-com/index.vue' /* webpackChunkName: "pages/online-school-com/index" */))
const _21043b52 = () => interopDefault(import('../pages/online-school-partners/index.vue' /* webpackChunkName: "pages/online-school-partners/index" */))
const _de9cf758 = () => interopDefault(import('../pages/otzyvy/index.vue' /* webpackChunkName: "pages/otzyvy/index" */))
const _002c560a = () => interopDefault(import('../pages/paywall/index.vue' /* webpackChunkName: "pages/paywall/index" */))
const _753932de = () => interopDefault(import('../pages/privacy/index.vue' /* webpackChunkName: "pages/privacy/index" */))
const _4ac3cbf6 = () => interopDefault(import('../pages/proforientacia/index.vue' /* webpackChunkName: "pages/proforientacia/index" */))
const _2d61006c = () => interopDefault(import('../pages/questionnaire/index.vue' /* webpackChunkName: "pages/questionnaire/index" */))
const _29e5ac78 = () => interopDefault(import('../pages/rekviziti/index.vue' /* webpackChunkName: "pages/rekviziti/index" */))
const _17246d3a = () => interopDefault(import('../pages/repetitor/index.vue' /* webpackChunkName: "pages/repetitor/index" */))
const _6bfca91b = () => interopDefault(import('../pages/rkvk/index.vue' /* webpackChunkName: "pages/rkvk/index" */))
const _326f8b74 = () => interopDefault(import('../pages/sale/index.vue' /* webpackChunkName: "pages/sale/index" */))
const _6a7fc950 = () => interopDefault(import('../pages/school-university/index.vue' /* webpackChunkName: "pages/school-university/index" */))
const _4a52b1e4 = () => interopDefault(import('../pages/soft-skills/index.vue' /* webpackChunkName: "pages/soft-skills/index" */))
const _1dbf3699 = () => interopDefault(import('../pages/spartak/index.vue' /* webpackChunkName: "pages/spartak/index" */))
const _11721924 = () => interopDefault(import('../pages/summer-sale/index.vue' /* webpackChunkName: "pages/summer-sale/index" */))
const _bc0cca14 = () => interopDefault(import('../pages/thanks/index.vue' /* webpackChunkName: "pages/thanks/index" */))
const _743b26fa = () => interopDefault(import('../pages/thanks-ceny/index.vue' /* webpackChunkName: "pages/thanks-ceny/index" */))
const _16202980 = () => interopDefault(import('../pages/thanks-shus/index.vue' /* webpackChunkName: "pages/thanks-shus/index" */))
const _363b44a6 = () => interopDefault(import('../pages/uchebnik/index.vue' /* webpackChunkName: "pages/uchebnik/index" */))
const _4129214c = () => interopDefault(import('../pages/catalog/abiturientam/index.vue' /* webpackChunkName: "pages/catalog/abiturientam/index" */))
const _6f8ae76a = () => interopDefault(import('../pages/catalog/courses/index.vue' /* webpackChunkName: "pages/catalog/courses/index" */))
const _a9098a0e = () => interopDefault(import('../pages/catalog/programmirovanie-dlya-detej/index.vue' /* webpackChunkName: "pages/catalog/programmirovanie-dlya-detej/index" */))
const _c4ad9f26 = () => interopDefault(import('../pages/catalog/repetitor/index.vue' /* webpackChunkName: "pages/catalog/repetitor/index" */))
const _b26a02f0 = () => interopDefault(import('../pages/ege/chelyabinsk/index.vue' /* webpackChunkName: "pages/ege/chelyabinsk/index" */))
const _c006f164 = () => interopDefault(import('../pages/ege/kazan/index.vue' /* webpackChunkName: "pages/ege/kazan/index" */))
const _448ab9b6 = () => interopDefault(import('../pages/ege/krasnoyarsk/index.vue' /* webpackChunkName: "pages/ege/krasnoyarsk/index" */))
const _24231f53 = () => interopDefault(import('../pages/ege/moscow/index.vue' /* webpackChunkName: "pages/ege/moscow/index" */))
const _b0153140 = () => interopDefault(import('../pages/ege/nizhny-novgorod/index.vue' /* webpackChunkName: "pages/ege/nizhny-novgorod/index" */))
const _5f7060d0 = () => interopDefault(import('../pages/ege/novosibirsk/index.vue' /* webpackChunkName: "pages/ege/novosibirsk/index" */))
const _cf796758 = () => interopDefault(import('../pages/ege/saint-petersburg/index.vue' /* webpackChunkName: "pages/ege/saint-petersburg/index" */))
const _b279606c = () => interopDefault(import('../pages/ege/samara/index.vue' /* webpackChunkName: "pages/ege/samara/index" */))
const _54c26fed = () => interopDefault(import('../pages/ege/ufa/index.vue' /* webpackChunkName: "pages/ege/ufa/index" */))
const _320b4b29 = () => interopDefault(import('../pages/ege/yekaterinburg/index.vue' /* webpackChunkName: "pages/ege/yekaterinburg/index" */))
const _61506f0f = () => interopDefault(import('../pages/events/open-day-08-04-2024/index.vue' /* webpackChunkName: "pages/events/open-day-08-04-2024/index" */))
const _0647387c = () => interopDefault(import('../pages/events/open-day-2023-05-30/index.vue' /* webpackChunkName: "pages/events/open-day-2023-05-30/index" */))
const _a14cdcb2 = () => interopDefault(import('../pages/events/open-day-21-03-2024/index.vue' /* webpackChunkName: "pages/events/open-day-21-03-2024/index" */))
const _d5814f6a = () => interopDefault(import('../pages/events/open-day-26-04-2024/index.vue' /* webpackChunkName: "pages/events/open-day-26-04-2024/index" */))
const _20498ff7 = () => interopDefault(import('../pages/externat/10-11klass/index.vue' /* webpackChunkName: "pages/externat/10-11klass/index" */))
const _a4873b74 = () => interopDefault(import('../pages/externat/5-7klass/index.vue' /* webpackChunkName: "pages/externat/5-7klass/index" */))
const _82d4773e = () => interopDefault(import('../pages/externat/8-9klass/index.vue' /* webpackChunkName: "pages/externat/8-9klass/index" */))
const _4f38e666 = () => interopDefault(import('../pages/free/ege/index.vue' /* webpackChunkName: "pages/free/ege/index" */))
const _4f4af0c0 = () => interopDefault(import('../pages/online-school/10-klass/index.vue' /* webpackChunkName: "pages/online-school/10-klass/index" */))
const _c016627e = () => interopDefault(import('../pages/online-school/11-klass/index.vue' /* webpackChunkName: "pages/online-school/11-klass/index" */))
const _3ae2fe60 = () => interopDefault(import('../pages/online-school/5-klass/index.vue' /* webpackChunkName: "pages/online-school/5-klass/index" */))
const _33385ed1 = () => interopDefault(import('../pages/online-school/6-klass/index.vue' /* webpackChunkName: "pages/online-school/6-klass/index" */))
const _f83b865c = () => interopDefault(import('../pages/online-school/7-klass/index.vue' /* webpackChunkName: "pages/online-school/7-klass/index" */))
const _56e7ca5a = () => interopDefault(import('../pages/online-school/8-klass/index.vue' /* webpackChunkName: "pages/online-school/8-klass/index" */))
const _2535f8d4 = () => interopDefault(import('../pages/online-school/9-klass/index.vue' /* webpackChunkName: "pages/online-school/9-klass/index" */))
const _097cc72a = () => interopDefault(import('../pages/online-school/attestacii-i-prikreplenie/index.vue' /* webpackChunkName: "pages/online-school/attestacii-i-prikreplenie/index" */))
const _1428907c = () => interopDefault(import('../pages/online-school/fiziko-matematicheskij/index.vue' /* webpackChunkName: "pages/online-school/fiziko-matematicheskij/index" */))
const _b67c7d36 = () => interopDefault(import('../pages/online-school/gumanitarnyj/index.vue' /* webpackChunkName: "pages/online-school/gumanitarnyj/index" */))
const _26bccc48 = () => interopDefault(import('../pages/online-school/lingvisticheskij/index.vue' /* webpackChunkName: "pages/online-school/lingvisticheskij/index" */))
const _6ab6791c = () => interopDefault(import('../pages/online-school/semeinoe-obuchenie/index.vue' /* webpackChunkName: "pages/online-school/semeinoe-obuchenie/index" */))
const _d58d234c = () => interopDefault(import('../pages/online-school/socialno-ekonomicheskij/index.vue' /* webpackChunkName: "pages/online-school/socialno-ekonomicheskij/index" */))
const _57cb4c74 = () => interopDefault(import('../pages/online-school/vechernee-obuchenie/index.vue' /* webpackChunkName: "pages/online-school/vechernee-obuchenie/index" */))
const _7a683164 = () => interopDefault(import('../pages/online-school/zaochnoe-obuchenie/index.vue' /* webpackChunkName: "pages/online-school/zaochnoe-obuchenie/index" */))
const _67c832f2 = () => interopDefault(import('../pages/proforientacia/9-11-klass/index.vue' /* webpackChunkName: "pages/proforientacia/9-11-klass/index" */))
const _052535ca = () => interopDefault(import('../pages/proforientacia/drb/index.vue' /* webpackChunkName: "pages/proforientacia/drb/index" */))
const _76cea31c = () => interopDefault(import('../pages/proforientacia/test/index.vue' /* webpackChunkName: "pages/proforientacia/test/index" */))
const _7b361ccc = () => interopDefault(import('../pages/promo/9-may/index.vue' /* webpackChunkName: "pages/promo/9-may/index" */))
const _f1528096 = () => interopDefault(import('../pages/promo/matematika-profil-polugod/index.vue' /* webpackChunkName: "pages/promo/matematika-profil-polugod/index" */))
const _5794fd20 = () => interopDefault(import('../pages/promo/russkij-yazyk-polugod/index.vue' /* webpackChunkName: "pages/promo/russkij-yazyk-polugod/index" */))
const _5268412c = () => interopDefault(import('../pages/questionnaire/OgeEge.vue' /* webpackChunkName: "pages/questionnaire/OgeEge" */))
const _193edb34 = () => interopDefault(import('../pages/tilda/itogovoe-sochinenie/index.vue' /* webpackChunkName: "pages/tilda/itogovoe-sochinenie/index" */))
const _2e06ffdb = () => interopDefault(import('../pages/tilda/lidforma-webinar/index.vue' /* webpackChunkName: "pages/tilda/lidforma-webinar/index" */))
const _61eb0321 = () => interopDefault(import('../pages/tilda/matematika-ege-2024/index.vue' /* webpackChunkName: "pages/tilda/matematika-ege-2024/index" */))
const _7323ded0 = () => interopDefault(import('../pages/tilda/russkii-yazik-ege-2024/index.vue' /* webpackChunkName: "pages/tilda/russkii-yazik-ege-2024/index" */))
const _296defc6 = () => interopDefault(import('../pages/tilda/semejnoe-obuchenie-27-11/index.vue' /* webpackChunkName: "pages/tilda/semejnoe-obuchenie-27-11/index" */))
const _ea83ea90 = () => interopDefault(import('../pages/catalog/courses/anglijskij-dlya-detej/index.vue' /* webpackChunkName: "pages/catalog/courses/anglijskij-dlya-detej/index" */))
const _413d8ef9 = () => interopDefault(import('../pages/catalog/courses/construct-3/index.vue' /* webpackChunkName: "pages/catalog/courses/construct-3/index" */))
const _2980b0d8 = () => interopDefault(import('../pages/catalog/courses/dizajn-prezentacij/index.vue' /* webpackChunkName: "pages/catalog/courses/dizajn-prezentacij/index" */))
const _659f5474 = () => interopDefault(import('../pages/catalog/courses/ekonomika-dlya-detej/index.vue' /* webpackChunkName: "pages/catalog/courses/ekonomika-dlya-detej/index" */))
const _4179a21a = () => interopDefault(import('../pages/catalog/courses/fin-gramotnost-base/index.vue' /* webpackChunkName: "pages/catalog/courses/fin-gramotnost-base/index" */))
const _560f3a5a = () => interopDefault(import('../pages/catalog/courses/fin-gramotnost-pro/index.vue' /* webpackChunkName: "pages/catalog/courses/fin-gramotnost-pro/index" */))
const _03bbf5d8 = () => interopDefault(import('../pages/catalog/courses/kurs-razvitie-rechi/index.vue' /* webpackChunkName: "pages/catalog/courses/kurs-razvitie-rechi/index" */))
const _0d90a0f0 = () => interopDefault(import('../pages/catalog/courses/literaturnyj-kruzhok/index.vue' /* webpackChunkName: "pages/catalog/courses/literaturnyj-kruzhok/index" */))
const _24102754 = () => interopDefault(import('../pages/catalog/courses/mentalnaya-arifmetika/index.vue' /* webpackChunkName: "pages/catalog/courses/mentalnaya-arifmetika/index" */))
const _6ce21880 = () => interopDefault(import('../pages/catalog/courses/osnovy-risunka/index.vue' /* webpackChunkName: "pages/catalog/courses/osnovy-risunka/index" */))
const _2b179bf6 = () => interopDefault(import('../pages/catalog/courses/python/index.vue' /* webpackChunkName: "pages/catalog/courses/python/index" */))
const _150c198a = () => interopDefault(import('../pages/catalog/courses/roblox/index.vue' /* webpackChunkName: "pages/catalog/courses/roblox/index" */))
const _4a5e622a = () => interopDefault(import('../pages/catalog/courses/uglublennaya-geometriya/index.vue' /* webpackChunkName: "pages/catalog/courses/uglublennaya-geometriya/index" */))
const _d63ce1bc = () => interopDefault(import('../pages/catalog/courses/uglublennyj-anglijskij/index.vue' /* webpackChunkName: "pages/catalog/courses/uglublennyj-anglijskij/index" */))
const _fad31896 = () => interopDefault(import('../pages/catalog/courses/web-programmirovanie/index.vue' /* webpackChunkName: "pages/catalog/courses/web-programmirovanie/index" */))
const _3a474a2f = () => interopDefault(import('../pages/events/open-day-2023-05-30/thank-you/index.vue' /* webpackChunkName: "pages/events/open-day-2023-05-30/thank-you/index" */))
const _3ce3042a = () => interopDefault(import('../pages/free/ege/kurs-ege-2024/index.vue' /* webpackChunkName: "pages/free/ege/kurs-ege-2024/index" */))
const _5bf6c3f7 = () => interopDefault(import('../pages/proforientacia/9-11-klass/kd/index.vue' /* webpackChunkName: "pages/proforientacia/9-11-klass/kd/index" */))
const _bd7f97aa = () => interopDefault(import('../pages/proforientacia/test/TestThanks.vue' /* webpackChunkName: "pages/proforientacia/test/TestThanks" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _5a246c1d = () => interopDefault(import('../pages/catalog/programmirovanie-dlya-detej/_first/index.vue' /* webpackChunkName: "pages/catalog/programmirovanie-dlya-detej/_first/index" */))
const _c55da7ae = () => interopDefault(import('../pages/catalog/repetitor/_first/index.vue' /* webpackChunkName: "pages/catalog/repetitor/_first/index" */))
const _a870cbf0 = () => interopDefault(import('../pages/proforientacia/test/_guid.vue' /* webpackChunkName: "pages/proforientacia/test/_guid" */))
const _a886c8c4 = () => interopDefault(import('../pages/catalog/programmirovanie-dlya-detej/_first/_second.vue' /* webpackChunkName: "pages/catalog/programmirovanie-dlya-detej/_first/_second" */))
const _a7b930a6 = () => interopDefault(import('../pages/catalog/repetitor/_first/_second/index.vue' /* webpackChunkName: "pages/catalog/repetitor/_first/_second/index" */))
const _32be8a1d = () => interopDefault(import('../pages/catalog/repetitor/_first/_second/_third.vue' /* webpackChunkName: "pages/catalog/repetitor/_first/_second/_third" */))
const _1e68c45c = () => interopDefault(import('../pages/articles/_id.vue' /* webpackChunkName: "pages/articles/_id" */))
const _35d98e34 = () => interopDefault(import('../pages/class/_index.vue' /* webpackChunkName: "pages/class/_index" */))
const _7b1a7d06 = () => interopDefault(import('../pages/ege/_subpage.vue' /* webpackChunkName: "pages/ege/_subpage" */))
const _4acd27bc = () => interopDefault(import('../pages/oge/_subpage.vue' /* webpackChunkName: "pages/oge/_subpage" */))
const _fcdadad0 = () => interopDefault(import('../pages/uchebnik/_class/index.vue' /* webpackChunkName: "pages/uchebnik/_class/index" */))
const _2e6cfb4c = () => interopDefault(import('../pages/uchebnik/_class/_subject/index.vue' /* webpackChunkName: "pages/uchebnik/_class/_subject/index" */))
const _693f0542 = () => interopDefault(import('../pages/uchebnik/_class/_subject/_lesson.vue' /* webpackChunkName: "pages/uchebnik/_class/_subject/_lesson" */))
const _59f29e44 = () => interopDefault(import('../pages/_material_category/index.vue' /* webpackChunkName: "pages/_material_category/index" */))
const _d9493ca8 = () => interopDefault(import('../pages/_material_category/_id.vue' /* webpackChunkName: "pages/_material_category/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/404",
    component: _8e80517e,
    name: "404"
  }, {
    path: "/additional-courses",
    component: _24784c02,
    name: "additional-courses"
  }, {
    path: "/articles",
    component: _1c4468ea,
    name: "articles"
  }, {
    path: "/documents",
    component: _e9bed37e,
    name: "documents"
  }, {
    path: "/ege",
    component: _f2765768,
    name: "ege"
  }, {
    path: "/ege-ceny",
    component: _a5675938,
    name: "ege-ceny"
  }, {
    path: "/enrollment",
    component: _74097f1e,
    name: "enrollment"
  }, {
    path: "/externat",
    component: _3a3c7700,
    name: "externat"
  }, {
    path: "/externat-ceny",
    component: _30724130,
    name: "externat-ceny"
  }, {
    path: "/externat-dlya-vzroslyh",
    component: _cd5f2b8e,
    name: "externat-dlya-vzroslyh"
  }, {
    path: "/free",
    component: _5b043759,
    name: "free"
  }, {
    path: "/gazprom-bonus",
    component: _375c6a5b,
    name: "gazprom-bonus"
  }, {
    path: "/halloween",
    component: _7f094cd4,
    name: "halloween"
  }, {
    path: "/happy-new-year-2024",
    component: _7524ecff,
    name: "happy-new-year-2024"
  }, {
    path: "/intensiv-ege",
    component: _13721baa,
    name: "intensiv-ege"
  }, {
    path: "/intensiv-oge",
    component: _47653335,
    name: "intensiv-oge"
  }, {
    path: "/intensive",
    component: _035b51a6,
    name: "intensive"
  }, {
    path: "/itogovoe-sobesedovanie-2024",
    component: _4a0e0d80,
    name: "itogovoe-sobesedovanie-2024"
  }, {
    path: "/itogovoe-sochinenie",
    component: _52178261,
    name: "itogovoe-sochinenie"
  }, {
    path: "/kod-budushchego",
    component: _6e75016b,
    name: "kod-budushchego"
  }, {
    path: "/marathon-ege-2024",
    component: _35f003ac,
    name: "marathon-ege-2024"
  }, {
    path: "/marathon-ege-2024-drb",
    component: _7e4aec31,
    name: "marathon-ege-2024-drb"
  }, {
    path: "/marathon-ege-matematika-2024",
    component: _6f9afe35,
    name: "marathon-ege-matematika-2024"
  }, {
    path: "/marathon-ege-russkij-2024",
    component: _0558216e,
    name: "marathon-ege-russkij-2024"
  }, {
    path: "/materials",
    component: _36d942b5,
    name: "materials"
  }, {
    path: "/oferta",
    component: _d5d21f44,
    name: "oferta"
  }, {
    path: "/oge",
    component: _5039d554,
    name: "oge"
  }, {
    path: "/oge-ceny",
    component: _5aff289a,
    name: "oge-ceny"
  }, {
    path: "/online-school",
    component: _5c494592,
    name: "online-school"
  }, {
    path: "/online-school-2",
    component: _65fb419c,
    name: "online-school-2"
  }, {
    path: "/online-school-ceny",
    component: _f7e4b54e,
    name: "online-school-ceny"
  }, {
    path: "/online-school-ceny-2",
    component: _54bc093e,
    name: "online-school-ceny-2"
  }, {
    path: "/online-school-ceny-dubai",
    component: _55c59da5,
    name: "online-school-ceny-dubai"
  }, {
    path: "/online-school-ceny-poddomen",
    component: _2e301850,
    name: "online-school-ceny-poddomen"
  }, {
    path: "/online-school-com",
    component: _6549b46b,
    name: "online-school-com"
  }, {
    path: "/online-school-partners",
    component: _21043b52,
    name: "online-school-partners"
  }, {
    path: "/otzyvy",
    component: _de9cf758,
    name: "otzyvy"
  }, {
    path: "/paywall",
    component: _002c560a,
    name: "paywall"
  }, {
    path: "/privacy",
    component: _753932de,
    name: "privacy"
  }, {
    path: "/proforientacia",
    component: _4ac3cbf6,
    name: "proforientacia"
  }, {
    path: "/questionnaire",
    component: _2d61006c,
    name: "questionnaire"
  }, {
    path: "/rekviziti",
    component: _29e5ac78,
    name: "rekviziti"
  }, {
    path: "/repetitor",
    component: _17246d3a,
    name: "repetitor"
  }, {
    path: "/rkvk",
    component: _6bfca91b,
    name: "rkvk"
  }, {
    path: "/sale",
    component: _326f8b74,
    name: "sale"
  }, {
    path: "/school-university",
    component: _6a7fc950,
    name: "school-university"
  }, {
    path: "/soft-skills",
    component: _4a52b1e4,
    name: "soft-skills"
  }, {
    path: "/spartak",
    component: _1dbf3699,
    name: "spartak"
  }, {
    path: "/summer-sale",
    component: _11721924,
    name: "summer-sale"
  }, {
    path: "/thanks",
    component: _bc0cca14,
    name: "thanks"
  }, {
    path: "/thanks-ceny",
    component: _743b26fa,
    name: "thanks-ceny"
  }, {
    path: "/thanks-shus",
    component: _16202980,
    name: "thanks-shus"
  }, {
    path: "/uchebnik",
    component: _363b44a6,
    name: "uchebnik"
  }, {
    path: "/catalog/abiturientam",
    component: _4129214c,
    name: "catalog-abiturientam"
  }, {
    path: "/catalog/courses",
    component: _6f8ae76a,
    name: "catalog-courses"
  }, {
    path: "/catalog/programmirovanie-dlya-detej",
    component: _a9098a0e,
    name: "catalog-programmirovanie-dlya-detej"
  }, {
    path: "/catalog/repetitor",
    component: _c4ad9f26,
    name: "catalog-repetitor"
  }, {
    path: "/ege/chelyabinsk",
    component: _b26a02f0,
    name: "ege-chelyabinsk"
  }, {
    path: "/ege/kazan",
    component: _c006f164,
    name: "ege-kazan"
  }, {
    path: "/ege/krasnoyarsk",
    component: _448ab9b6,
    name: "ege-krasnoyarsk"
  }, {
    path: "/ege/moscow",
    component: _24231f53,
    name: "ege-moscow"
  }, {
    path: "/ege/nizhny-novgorod",
    component: _b0153140,
    name: "ege-nizhny-novgorod"
  }, {
    path: "/ege/novosibirsk",
    component: _5f7060d0,
    name: "ege-novosibirsk"
  }, {
    path: "/ege/saint-petersburg",
    component: _cf796758,
    name: "ege-saint-petersburg"
  }, {
    path: "/ege/samara",
    component: _b279606c,
    name: "ege-samara"
  }, {
    path: "/ege/ufa",
    component: _54c26fed,
    name: "ege-ufa"
  }, {
    path: "/ege/yekaterinburg",
    component: _320b4b29,
    name: "ege-yekaterinburg"
  }, {
    path: "/events/open-day-08-04-2024",
    component: _61506f0f,
    name: "events-open-day-08-04-2024"
  }, {
    path: "/events/open-day-2023-05-30",
    component: _0647387c,
    name: "events-open-day-2023-05-30"
  }, {
    path: "/events/open-day-21-03-2024",
    component: _a14cdcb2,
    name: "events-open-day-21-03-2024"
  }, {
    path: "/events/open-day-26-04-2024",
    component: _d5814f6a,
    name: "events-open-day-26-04-2024"
  }, {
    path: "/externat/10-11klass",
    component: _20498ff7,
    name: "externat-10-11klass"
  }, {
    path: "/externat/5-7klass",
    component: _a4873b74,
    name: "externat-5-7klass"
  }, {
    path: "/externat/8-9klass",
    component: _82d4773e,
    name: "externat-8-9klass"
  }, {
    path: "/free/ege",
    component: _4f38e666,
    name: "free-ege"
  }, {
    path: "/online-school/10-klass",
    component: _4f4af0c0,
    name: "online-school-10-klass"
  }, {
    path: "/online-school/11-klass",
    component: _c016627e,
    name: "online-school-11-klass"
  }, {
    path: "/online-school/5-klass",
    component: _3ae2fe60,
    name: "online-school-5-klass"
  }, {
    path: "/online-school/6-klass",
    component: _33385ed1,
    name: "online-school-6-klass"
  }, {
    path: "/online-school/7-klass",
    component: _f83b865c,
    name: "online-school-7-klass"
  }, {
    path: "/online-school/8-klass",
    component: _56e7ca5a,
    name: "online-school-8-klass"
  }, {
    path: "/online-school/9-klass",
    component: _2535f8d4,
    name: "online-school-9-klass"
  }, {
    path: "/online-school/attestacii-i-prikreplenie",
    component: _097cc72a,
    name: "online-school-attestacii-i-prikreplenie"
  }, {
    path: "/online-school/fiziko-matematicheskij",
    component: _1428907c,
    name: "online-school-fiziko-matematicheskij"
  }, {
    path: "/online-school/gumanitarnyj",
    component: _b67c7d36,
    name: "online-school-gumanitarnyj"
  }, {
    path: "/online-school/lingvisticheskij",
    component: _26bccc48,
    name: "online-school-lingvisticheskij"
  }, {
    path: "/online-school/semeinoe-obuchenie",
    component: _6ab6791c,
    name: "online-school-semeinoe-obuchenie"
  }, {
    path: "/online-school/socialno-ekonomicheskij",
    component: _d58d234c,
    name: "online-school-socialno-ekonomicheskij"
  }, {
    path: "/online-school/vechernee-obuchenie",
    component: _57cb4c74,
    name: "online-school-vechernee-obuchenie"
  }, {
    path: "/online-school/zaochnoe-obuchenie",
    component: _7a683164,
    name: "online-school-zaochnoe-obuchenie"
  }, {
    path: "/proforientacia/9-11-klass",
    component: _67c832f2,
    name: "proforientacia-9-11-klass"
  }, {
    path: "/proforientacia/drb",
    component: _052535ca,
    name: "proforientacia-drb"
  }, {
    path: "/proforientacia/test",
    component: _76cea31c,
    name: "proforientacia-test"
  }, {
    path: "/promo/9-may",
    component: _7b361ccc,
    name: "promo-9-may"
  }, {
    path: "/promo/matematika-profil-polugod",
    component: _f1528096,
    name: "promo-matematika-profil-polugod"
  }, {
    path: "/promo/russkij-yazyk-polugod",
    component: _5794fd20,
    name: "promo-russkij-yazyk-polugod"
  }, {
    path: "/questionnaire/OgeEge",
    component: _5268412c,
    name: "questionnaire-OgeEge"
  }, {
    path: "/tilda/itogovoe-sochinenie",
    component: _193edb34,
    name: "tilda-itogovoe-sochinenie"
  }, {
    path: "/tilda/lidforma-webinar",
    component: _2e06ffdb,
    name: "tilda-lidforma-webinar"
  }, {
    path: "/tilda/matematika-ege-2024",
    component: _61eb0321,
    name: "tilda-matematika-ege-2024"
  }, {
    path: "/tilda/russkii-yazik-ege-2024",
    component: _7323ded0,
    name: "tilda-russkii-yazik-ege-2024"
  }, {
    path: "/tilda/semejnoe-obuchenie-27-11",
    component: _296defc6,
    name: "tilda-semejnoe-obuchenie-27-11"
  }, {
    path: "/catalog/courses/anglijskij-dlya-detej",
    component: _ea83ea90,
    name: "catalog-courses-anglijskij-dlya-detej"
  }, {
    path: "/catalog/courses/construct-3",
    component: _413d8ef9,
    name: "catalog-courses-construct-3"
  }, {
    path: "/catalog/courses/dizajn-prezentacij",
    component: _2980b0d8,
    name: "catalog-courses-dizajn-prezentacij"
  }, {
    path: "/catalog/courses/ekonomika-dlya-detej",
    component: _659f5474,
    name: "catalog-courses-ekonomika-dlya-detej"
  }, {
    path: "/catalog/courses/fin-gramotnost-base",
    component: _4179a21a,
    name: "catalog-courses-fin-gramotnost-base"
  }, {
    path: "/catalog/courses/fin-gramotnost-pro",
    component: _560f3a5a,
    name: "catalog-courses-fin-gramotnost-pro"
  }, {
    path: "/catalog/courses/kurs-razvitie-rechi",
    component: _03bbf5d8,
    name: "catalog-courses-kurs-razvitie-rechi"
  }, {
    path: "/catalog/courses/literaturnyj-kruzhok",
    component: _0d90a0f0,
    name: "catalog-courses-literaturnyj-kruzhok"
  }, {
    path: "/catalog/courses/mentalnaya-arifmetika",
    component: _24102754,
    name: "catalog-courses-mentalnaya-arifmetika"
  }, {
    path: "/catalog/courses/osnovy-risunka",
    component: _6ce21880,
    name: "catalog-courses-osnovy-risunka"
  }, {
    path: "/catalog/courses/python",
    component: _2b179bf6,
    name: "catalog-courses-python"
  }, {
    path: "/catalog/courses/roblox",
    component: _150c198a,
    name: "catalog-courses-roblox"
  }, {
    path: "/catalog/courses/uglublennaya-geometriya",
    component: _4a5e622a,
    name: "catalog-courses-uglublennaya-geometriya"
  }, {
    path: "/catalog/courses/uglublennyj-anglijskij",
    component: _d63ce1bc,
    name: "catalog-courses-uglublennyj-anglijskij"
  }, {
    path: "/catalog/courses/web-programmirovanie",
    component: _fad31896,
    name: "catalog-courses-web-programmirovanie"
  }, {
    path: "/events/open-day-2023-05-30/thank-you",
    component: _3a474a2f,
    name: "events-open-day-2023-05-30-thank-you"
  }, {
    path: "/free/ege/kurs-ege-2024",
    component: _3ce3042a,
    name: "free-ege-kurs-ege-2024"
  }, {
    path: "/proforientacia/9-11-klass/kd",
    component: _5bf6c3f7,
    name: "proforientacia-9-11-klass-kd"
  }, {
    path: "/proforientacia/test/TestThanks",
    component: _bd7f97aa,
    name: "proforientacia-test-TestThanks"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }, {
    path: "/catalog/programmirovanie-dlya-detej/:first",
    component: _5a246c1d,
    name: "catalog-programmirovanie-dlya-detej-first"
  }, {
    path: "/catalog/repetitor/:first",
    component: _c55da7ae,
    name: "catalog-repetitor-first"
  }, {
    path: "/proforientacia/test/:guid",
    component: _a870cbf0,
    name: "proforientacia-test-guid"
  }, {
    path: "/catalog/programmirovanie-dlya-detej/:first?/:second?",
    component: _a886c8c4,
    name: "catalog-programmirovanie-dlya-detej-first-second"
  }, {
    path: "/catalog/repetitor/:first/:second",
    component: _a7b930a6,
    name: "catalog-repetitor-first-second"
  }, {
    path: "/catalog/repetitor/:first/:second/:third",
    component: _32be8a1d,
    name: "catalog-repetitor-first-second-third"
  }, {
    path: "/articles/:id",
    component: _1e68c45c,
    name: "articles-id"
  }, {
    path: "/class/:index",
    component: _35d98e34,
    name: "class"
  }, {
    path: "/ege/:subpage",
    component: _7b1a7d06,
    name: "ege-subpage"
  }, {
    path: "/oge/:subpage",
    component: _4acd27bc,
    name: "oge-subpage"
  }, {
    path: "/uchebnik/:class",
    component: _fcdadad0,
    name: "uchebnik-class"
  }, {
    path: "/uchebnik/:class/:subject",
    component: _2e6cfb4c,
    name: "uchebnik-class-subject"
  }, {
    path: "/uchebnik/:class/:subject/:lesson",
    component: _693f0542,
    name: "uchebnik-class-subject-lesson"
  }, {
    path: "/:material_category",
    component: _59f29e44,
    name: "material_category"
  }, {
    path: "/:material_category/:id",
    component: _d9493ca8,
    name: "material_category-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
